import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import Typography from '@mui/material/Typography';
import { Box, Button, Container, Link } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
// import CustomDateWidget from './CustomDateWidget';
import api from './API'
const logo = require('./gd_logo_png.png');
const bg_img = require('./gd_bg_image_small.jpg')

const theme = createTheme({
  palette: {
    primary: {
      main: '#DBC554',
    },
    secondary: {
      main: '#C9A555'
    }
  },
  typography: {
    fontFamily: 'Open Sans',
  },
});
function App() {
  const [isValidated, setIsValidated] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [formData, setFormData] = useState(Object);
  const [currentPage, setCurrentPage] = useState(1);
  const uRLQueryString = window.location.search;
  console.log(uRLQueryString);
  const urlParams = new URLSearchParams(uRLQueryString);
  const reservationNumber = urlParams.get('ReservationID');
  const [loading, setLoading] = React.useState(false);
  console.log(reservationNumber);
  const convertDate = (input: string) => {
    // Parse the input date string
    const date = new Date(input);

    // Extract the day, month, and year
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const year = date.getFullYear();

    // Format the date as dd-mm-yyyy
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  const handleValidation = async () => {
    setLoading(true);
    const validationJSON = await api.getValidation();
    setLoading(false);
    const arrivalDate = convertDate(validationJSON?.vwm_arrivaldatemaestro!);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // if (new Date(arrivalDate) < today) {
    //   alert("Your visit has passed. Thank you for staying with us.");
    //   return;
    // }
    if (validationJSON?.message!) {
      alert(validationJSON?.message!);
    }
    else {
      if (validationJSON?.lastname!.toLowerCase() === formData?.lastName!.toLowerCase() && arrivalDate === formData?.arrivalDate!) {
        setIsValidated(true);
      } else {
        alert("Validation Failed");
      }
    }
  };
  const handleViewOnly = (data: any) => {
    setFormData(data.formData);
    setIsViewOnly(true);
  }
  const handleEdit = () => {
    setIsViewOnly(false);
    setCurrentPage(1);
  };
  const handleSubmit = async () => {
    formData.reservationNumber = reservationNumber;
    setFormData(formData);
    console.log('Final form submission', formData);
    const postFormDataForForm = await api.postFormDataForFlow(formData);
    console.log(postFormDataForForm);
    if (postFormDataForForm === "Success") {
      handleNext();
    }
    else {
      alert("Some error occured while making post request");
    }

    setIsViewOnly(false);
  };
  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(currentPage + 1);
    console.log(currentPage)
  };
  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(currentPage - 1);
  };
  const customValidateDateRange = (formData: any, errors: any) => {
    const selectedDate = new Date(formData.dob);
    const minDate = new Date("1920-01-01");
    const maxDate = new Date("2050-12-31");

    if (selectedDate < minDate) {
      errors.dob.addError("The date must be on or after January 1, 1920.");
    } else if (selectedDate > maxDate) {
      errors.dob.addError("The date must be on or before December 31, 2050.");
    }
    return errors;
  };
  const transformErrorsEmailFormat = (errors: any) => {
    return errors.map((error: any) => {
      if (error.name === "pattern" && error.property === ".email") {
        error.message = "Please enter a valid email address.";
      }
      return error;
    });
  };
  const schema: RJSFSchema = {
    type: 'object',
    required: ['firstName', 'lastName', 'arrivalDate', 'clothingSizeTop', 'yukataKimono'],
    properties: {
      firstName: { type: 'string', title: 'First Name' },
      lastName: { type: 'string', title: 'Last Name' },
      arrivalDate: { type: 'string', title: 'Arrival Date', format: 'date' },
      clothingSizeTop: {
        type: 'string',
        title: 'Clothing Size (Shirt & Jacket)',
        oneOf: [
          {
            const: 'S',
            title: 'Small'
          },
          {
            const: 'M',
            title: 'Medium'
          },
          {
            const: 'L',
            title: 'Large'
          },
          {
            const: 'XL',
            title: 'X Large'
          },
          {
            const: '2XL',
            title: 'XX Large'
          },
          {
            const: '3XL',
            title: 'XXX Large'
          },
        ]
      },
      clothingSizeBottom: {
        type: 'string',
        title: 'Clothing Size (Bottoms)',
        oneOf: [
          {
            const: 'S',
            title: 'Small'
          },
          {
            const: 'M',
            title: 'Medium'
          },
          {
            const: 'L',
            title: 'Large'
          },
          {
            const: 'XL',
            title: 'X Large'
          },
          {
            const: '2XL',
            title: 'XX Large'
          },
          {
            const: '3XL',
            title: 'XXX Large'
          },
        ]
      },
      yukataKimono: {
        type: 'string',
        title: 'Yukata Kimono',
        description: 'A traditional Japanese Yukata kimono is worn nightly to dinner service. We are able to provide kimonos with a shorter length if needed. Please indicate below if you would prefer a short kimono.',
        oneOf: [
          {
            const: 'Short',
            title: 'Short'
          },
          {
            const: 'Standard',
            title: 'Standard'
          }
        ]
      },
      shoeSize: {
        type: 'string',
        title: 'Shoe Size (US)',
        enum: ['5', '6', '7', '8', '9', '10', '11', '12', '13'],
      },
      portionSize: {
        type: 'string',
        title: 'Meal Portion',
        description: 'All of our meals are based on a 1400 calorie per day program. Our kitchen is able to accommodate smaller or larger portions if needed. Please indicate below which portion size you would prefer. (This is applied to all meals, however, can be adjusted as needed during your stay)',
        enum: ['Small', 'Standard', 'Tall'],
      },
      ice: {
        type: 'boolean',
        title: 'Would you like ice with your water during meals?',
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      waterType: {
        type: 'string',
        title: 'What type of water would you like provided in your room?',
        oneOf: [
          {
            const: 'Alkaline',
            title: 'Alkaline (8.76 pH)'
          },
          {
            const: 'Filtered',
            title: 'Filtered'
          }
        ]
      },
      mondayBreakfast: {
        type: 'string',
        title: 'Breakfast: Monday’s Choices',
        description: 'You will have the ability to choose/customize your meals each day after your arrival. Please choose your preferred breakfast option for Monday only.',
        enum: ['Steel Cut Oatmeal | Toasted almonds, chia seeds, currants & honey; gluten-free, dairy-free oatmeal is available upon request.', 'Greek Yogurt with a sprinkle of granola and fresh Fruit or Berries; 0% Greek yogurt or non-dairy yogurt is also available upon request.', 'Golden Door Rolled Omelet | Farm fresh eggs, cooked and rolled in a fluffy thick rolled omelet, with Pecorino and Romano cheeses, topped with fresh herbs.'],
      },
      breakfastBeverages: {
        type: 'array',
        title: 'Daily Breakfast Beverage Choices (may select multiple)',
        items: {
          enum: [
            'Coffee', 'Decaf coffee', 'Caffeinated Black Tea', 'Caffeinated Green Tea', 'Organic Decaf Herbal Teas', 'Milk', 'Non-dairy cream', 'Cream', 'Sugar or Sugar Substitute', 'Fresh Orange Juice'
          ],
          type: 'string'
        },
        uniqueItems: true,
      },
      foodAllergies: {
        type: 'string',
        title: 'Food Allergies',
        description: 'Please list any food allergies, mild to severe. If you do not have any food allergies, please enter "none".',
      },
      dieteryRestrictions: {
        type: 'array',
        title: 'Dietary Preferences and/or Restrictions',
        items: {
          enum: ['No preferences or restrictions', 'Vegan', 'Vegetarian', 'Pescatarian', 'Gluten-free', 'Dairy-free'],
          type: 'string'
        },
        uniqueItems: true,
      },
      nonFoodAllergies: {
        type: 'string',
        title: 'Non-Food Allergies',
        description: 'Please list any non-food allergies that we should be aware of during your stay. If you do not have any non-food allergies, please enter "none".',
      },
      medical: {
        type: 'string',
        title: 'Medical',
        description: 'Please note any medical issues/concerns our staff should be aware of. If there are no medical issues, please enter "none".',
      },
      ftGender: {
        type: 'string',
        title: 'Fitness Trainer',
        description: 'Do you have a gender preference for your personal trainer?',
        oneOf: [
          {
            const: 'Either',
            title: 'No Preferences'
          },
          {
            const: 'Female',
            title: 'Female'
          },
          {
            const: 'Male',
            title: 'Male'
          }
        ]
      },
      fitnessLevel: {
        type: 'string',
        title: 'Fitness Level',
        enum: ['Light', 'Moderate', 'Challenging'],
      },
      massage: {
        type: 'string',
        title: 'Massage',
        description: 'Do you have a gender preference for your massage therapist?',
        oneOf: [
          {
            const: 'Either',
            title: 'No Preferences'
          },
          {
            const: 'Female',
            title: 'Female'
          },
          {
            const: 'Male',
            title: 'Male'
          }
        ]
      },
      massageTimePreference: {
        type: "array",
        title: "Massage Time Preference",
        description: "All services will be booked ahead of your arrival. Please check the above times in order of preference for your daily massage. Our team will do their best to accommodate one of your selections, however, time preferences may not be guaranteed. Please provide multiple time preferences.",
        items: {
          type: "string",
          enum: ["Anytime", "11AM", "12PM", "2PM", "3PM", "4PM", "5PM"]
        },
        uniqueItems: true
      },
      massageExtension: {
        type: "string",
        title: "Massage Time Extension",
        description:"Would you like to add an extension to your massage?",
        enum: ['No','15 minutes', '30 minutes', '45 minutes'],
      },
      arrivalDayMassage: {
        type: 'boolean',
        title: "Arrival day Massage",
        description:"Are you interested in receiving an arrival day massage?",
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      skinCare: {
        type: 'array',
        title: 'Skin Care',
        description: 'All services will be booked ahead of your arrival. Please check the above times in order of preference for your daily massage. Our team will do their best to accommodate one of your selections, however, time preferences may not be guaranteed. Please provide multiple time preferences.',
        items: {
          enum: ['Anytime', '10AM', '11AM', '12PM', '2PM', '3PM', '4PM', '5PM'],
          type: 'string'
        },
        uniqueItems: true,
      },
      skinanalysis: {
        type: 'boolean',
        title: 'Complimentary Skin Analysis',
        description: 'Would you like to receive a complimentary skin analysis to allow further customization to your skin care treatment?',
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      herbalWraps: {
        type: 'boolean',
        title: 'Herbal Wraps',
        description: 'Our warm rosemary herbal wraps are a long-standing tradition. Please indicate whether or not you would like to receive this inclusive service.',
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      nailServices: {
        type: 'array',
        title: 'Nail Services',
        description: '7-day guests receive a choice of TWO treatments below. If you are staying fewer than 7 days, please select ONE of the options below.',
        items: {
          enum: [
            'Manicure', 'Pedicure', 'Hand treatment', 'Foot treatment'
          ],
          type: 'string'
        },
        uniqueItems: true,
      },
      hairConsultation: {
        type: 'boolean',
        title: 'Hair Consultation',
        description: 'We offer a complimentary hair consultation for those who may be interested in receiving a hair services (cut, color, etc.).',
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      hairServices: {
        type: 'string',
        title: 'Inclusive Hair Services',
        description: '7-day guests receive one of the following hair services at the end of the week. Please select one of the options below. ',
        enum: ['Shampoo & Blow Dry', 'Deep Conditioning Treatment', 'Scalp Treatment', 'None'],
      },
      firstVisit: {
        type: 'boolean',
        title: 'Is this your first visit with us?',
        oneOf: [
          {
            const: true,
            title: 'Yes'
          },
          {
            const: false,
            title: 'No'
          }
        ]
      },
      email: {
        type: 'string',
        title: 'Email',
        pattern: "^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$",
      },
      dob: {
        type: 'string',
        title: 'Date of Birth'
      }
    },
  };
  const contactDetailsSchema: RJSFSchema = {
    type: 'object',
    required: ['firstVisit', 'email', 'dob'],
    properties: {
      firstVisit: schema.properties?.firstVisit!,
      email: schema.properties?.email!,
      dob: schema.properties?.dob!
    },
  };
  const houseKeepingSchema: RJSFSchema = {
    type: 'object',
    required: ['clothingSizeTop', 'yukataKimono', 'clothingSizeBottom', 'shoeSize'],
    properties: {
      clothingSizeTop: schema.properties?.clothingSizeTop!,
      clothingSizeBottom: schema.properties?.clothingSizeBottom!,
      shoeSize: schema.properties?.shoeSize!,
      yukataKimono: schema.properties?.yukataKimono!,
    },
  };
  const kitchenMasterSchema: RJSFSchema = {
    type: 'object',
    required: ['portionSize', 'ice', 'waterType', 'mondayBreakfast', 'breakfastBeverages', 'foodAllergies', 'dieteryRestrictions', 'nonFoodAllergies', 'medical'],
    properties: {
      portionSize: schema.properties?.portionSize!,
      ice: schema.properties?.ice!,
      waterType: schema.properties?.waterType!,
      mondayBreakfast: schema.properties?.mondayBreakfast!,
      breakfastBeverages: schema.properties?.breakfastBeverages!,
      foodAllergies: schema.properties?.foodAllergies!,
      dieteryRestrictions: schema.properties?.dieteryRestrictions!,
      nonFoodAllergies: schema.properties?.nonFoodAllergies!,
      medical: schema.properties?.medical!,
    },
  };
  const spaAndFitness: RJSFSchema = {
    type: 'object',
    required: ['ftGender', 'fitnessLevel', 'massage', 'massageTimePreference', 'massageExtension','arrivalDayMassage','skinCare', 'skinanalysis', 'herbalWraps', 'nailServices', 'hairConsultation', 'hairServices'],
    properties: {
      ftGender: schema.properties?.ftGender!,
      fitnessLevel: schema.properties?.fitnessLevel!,
      massage: schema.properties?.massage!,
      massageTimePreference: schema.properties?.massageTimePreference!,
      massageExtension:schema.properties?.massageExtension!,
      arrivalDayMassage:schema.properties?.arrivalDayMassage!,
      skinCare: schema.properties?.skinCare!,
      skinanalysis: schema.properties?.skinanalysis!,
      herbalWraps: schema.properties?.herbalWraps!,
      nailServices: schema.properties?.nailServices!,
      hairConsultation: schema.properties?.hairConsultation!,
      hairServices: schema.properties?.hairServices!,
    },
  };
  const validateSchema: RJSFSchema = {
    type: 'object',
    required: ['lastName', 'arrivalDate'],
    properties: {
      lastName: { type: 'string', title: 'Last Name' },
      arrivalDate: { type: 'string', title: 'Arrival Date', format: 'date' },
    },
  };

  const uiSchema = {
    firstName: { 'ui:widget': 'text' },
    lastName: { 'ui:widget': 'text' },
    email: { 'ui:widget': 'text' },
    arrivalDate: { 'ui:widget': 'date' },
    clothingSizeTop: { 'ui:widget': 'select' },
    yukataKimono: { 'ui:widget': 'radio' },
    shoeSize: { 'ui:widget': 'select' },
    clothingSizeBottom: { 'ui:widget': 'select' },
    ice: { 'ui:widget': 'radio' },
    waterType: { 'ui:widget': 'select' },
    mondayBreakfast: { 'ui:widget': 'select' },
    breakfastBeverages: { 'ui:widget': 'checkboxes' },
    foodAllergies: { 'ui:widget': 'text' },
    dieteryRestrictions: { 'ui:widget': 'checkboxes' },
    nonFoodAllergies: { 'ui:widget': 'text' },
    medical: { 'ui:widget': 'text' },
    fitnessLevel: { 'ui:widget': 'select' },
    massage: { 'ui:widget': 'select' },
    massageTimePreference: { 'ui:widget': 'checkboxes' },
    massageExtension:{'ui:widget': 'select'},
    arrivalDayMassage: {'ui:widget': 'radio' },
    skinCare: { 'ui:widget': 'checkboxes' },
    skinanalysis: { 'ui:widget': 'radio' },
    herbalWraps: { 'ui:widget': 'radio' },
    nailServices: { 'ui:widget': 'checkboxes' },
    hairConsultation: { 'ui:widget': 'radio' },
    hairServices: { 'ui:widget': 'select' },
    firstVisit: { 'ui:widget': 'radio' },
    dob: { 'ui:widget': 'date' },
  };
  const log = (type: string) => console.log.bind(console, type);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          p: 2,
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${bg_img})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.7,
            zIndex: -1,
          }
        }}
      >
        {!isValidated ? (
          <Box
            maxWidth={'35%'}
            width="100%"
            bgcolor="rgba(255, 255, 255, 0.9)"
            boxShadow="0 0 10px rgba(0,0,0,0.1)"
            borderRadius="8px"
            overflow="hidden"
            p={3}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              display={'flex'}
            >
              <img src={logo} style={{ minWidth: '50%' }} width="50%" alt="Icon" />
            </Box>
            <Form
              schema={validateSchema}
              uiSchema={uiSchema}
              validator={validator}
              onChange={e => { setFormData(e.formData) }}
              onError={log('errors')}
              showErrorList={false}
              onSubmit={handleValidation}
            />
          </Box>
        ) : isViewOnly ? (
          <Box
            maxWidth={'35%'}
            width="100%"
            bgcolor="rgba(255, 255, 255, 0.9)"
            boxShadow="0 0 10px rgba(0,0,0,0.1)"
            borderRadius="8px"
            overflow="hidden"
            p={3}
            display="flex"
            flexDirection="column"
            alignItems="center" // Centers the image horizontally
          >
            <img src={logo} style={{ minWidth: '50%' }} width="50%" alt="Icon" />
            <Box width="100%" mt={2}>
              <Typography variant="h5" gutterBottom>Review your answers</Typography>
              <Typography variant="body1"><strong>Reservation ID:</strong> {reservationNumber}</Typography>
              <Typography variant="body1"><strong>Is this your first visit with us?:</strong> {formData.firstVisit ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {formData.email}</Typography>
              <Typography variant="body1"><strong>Date Of Birth:</strong> {formData.dob}</Typography>
              {/* <Typography variant="body1"><strong>Last Name:</strong> {formData.lastName}</Typography>
                      <Typography variant="body1"><strong>Arrival Date:</strong> {formData.arrivalDate}</Typography> */}
              <Typography variant="body1"><strong>Clothing Size (Shirt and Jacket):</strong> {formData.clothingSizeTop}</Typography>
              <Typography variant="body1"><strong>Yukata Kimono:</strong> {formData.yukataKimono ? 'Short' : 'Standard'}</Typography>
              <Typography variant="body1"><strong>Shoe Size:</strong> {formData.shoeSize}</Typography>
              <Typography variant="body1"><strong>Clothing Size (Bottoms):</strong> {formData.clothingSizeBottom}</Typography>
              <Typography variant="body1"><strong>Meal Portion:</strong> {formData.portionSize}</Typography>
              <Typography variant="body1"><strong>Would you like ice with your water during meals?:</strong> {formData.ice ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>What type of water would you like provided in your room?:</strong> {formData.waterType}</Typography>
              <Typography variant="body1"><strong>Breakfast: Monday’s Choices:</strong> {formData.mondayBreakfast}</Typography>
              <Typography variant="body1"><strong>Daily Breakfast Beverage Choices (may select multiple):</strong> {formData.breakfastBeverages.toString()}</Typography>
              <Typography variant="body1"><strong>Food Allergies:</strong> {formData.foodAllergies}</Typography>
              <Typography variant="body1"><strong>Dietary Preferences and/or Restrictions:</strong> {formData.dieteryRestrictions.toString()}</Typography>
              <Typography variant="body1"><strong>Non-Food Allergies:</strong> {formData.nonFoodAllergies}</Typography>
              <Typography variant="body1"><strong>Medical:</strong> {formData.medical}</Typography>
              <Typography variant="body1"><strong>Fitness Trainer:</strong> {formData.ftGender}</Typography>
              <Typography variant="body1"><strong>Massage:</strong> {formData.massage}</Typography>
              <Typography variant="body1"><strong>Massage Time Preference:</strong> {formData.massageTimePreference.toString()}</Typography>
              <Typography variant="body1"><strong>Massage Extension:</strong> {formData.massageExtension}</Typography>
              <Typography variant="body1"><strong>Arrival Day Massage:</strong> {formData.arrivalDayMassage ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Skin Care:</strong> {formData.skinCare.toString()}</Typography>
              <Typography variant="body1"><strong>Skin Analysis:</strong> {formData.skinanalysis ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Herbal Wraps:</strong> {formData.herbalWraps ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Nail Services:</strong> {formData.nailServices.toString()}</Typography>
              <Typography variant="body1"><strong>Hair Consultation:</strong> {formData.hairConsultation ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Inclusive Hair Services:</strong> {formData.hairServices}</Typography>
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
              <Button variant="outlined" color="secondary" onClick={handleEdit} sx={{ ml: 2 }}>Edit</Button>
            </Box>
          </Box>) : (
          <Box
            maxWidth={'35%'}
            width="100%"
            bgcolor="rgba(255, 255, 255, 0.9)"
            boxShadow="0 0 10px rgba(0,0,0,0.1)"
            borderRadius="8px"
            overflow="hidden"
            p={3}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              display={'flex'}
            >
              <img src={logo} style={{ minWidth: '50%' }} width="50%" alt="Icon" />
            </Box>
            {currentPage === 1 && (
              <>
                <Form
                  schema={contactDetailsSchema}
                  uiSchema={uiSchema}
                  formData={formData}
                  onChange={e => { setFormData(e.formData) }}
                  onSubmit={handleNext}
                  validator={validator}
                  customValidate={customValidateDateRange}
                  showErrorList={false}
                  transformErrors={transformErrorsEmailFormat}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Next
                  </Button>
                </Form>
              </>
            )}
            {currentPage === 2 && (
              <>
                <Form
                  schema={houseKeepingSchema}
                  uiSchema={uiSchema}
                  formData={formData}
                  onChange={e => { setFormData(e.formData) }}
                  onSubmit={handleNext}
                  validator={validator}
                >
                  <Button onClick={handleBack} variant="contained" color="secondary" sx={{ mr: 2 }}>
                    Back
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Next
                  </Button>
                </Form>
              </>
            )}
            {currentPage === 3 && (
              <>
                <Form
                  schema={kitchenMasterSchema}
                  uiSchema={uiSchema}
                  formData={formData}
                  onChange={e => { setFormData(e.formData) }}
                  onSubmit={handleNext}
                  validator={validator}
                >
                  <Button onClick={handleBack} variant="contained" color="secondary" sx={{ mr: 2 }}>
                    Back
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Next
                  </Button>
                </Form>
              </>
            )}
            {currentPage === 4 && (
              <>
                <Form
                  schema={spaAndFitness}
                  uiSchema={uiSchema}
                  formData={formData}
                  onChange={e => { setFormData(e.formData) }}
                  onSubmit={handleViewOnly}
                  validator={validator}
                >
                  <Button onClick={handleBack} variant="contained" color="secondary" sx={{ mr: 2 }}>
                    Back
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Next
                  </Button>
                </Form>
              </>
            )}
            {currentPage === 5 && (
              <>
                <Container>
                  {formData.firstVisit &&
                    <Box mt={4}>
                      <Typography variant="body1" paragraph>
                        Thank you for completing our pre-arrival questionnaire. You may now schedule your specialized phone interview with one of our pre-arrival coordinators.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        To schedule a pre-arrival call, please click the link below or send an e-mail to: <Link href="mailto:prearrivalinterview@goldendoor.com">prearrivalinterview@goldendoor.com</Link>
                      </Typography>
                      <Box mt={2} textAlign="center">
                        <Link href="https://outlook.office365.com/owa/calendar/GoldenDoor@goldendoor.com/bookings/" target="_blank" rel="noopener"
                          style={{ fontWeight: 'bold', textDecoration: 'none', fontSize: 22 }}>
                          Schedule Pre-arrival Call
                        </Link>
                      </Box>
                    </Box>}

                  {!formData.firstVisit && <Box mt={4}>
                    <Typography variant="body1" paragraph>
                      We look forward to welcoming you back soon. If you have any updates since your most recent visit and would like to speak with one of our pre-arrival coordinators, please click the link below or send an e-mail to: <Link href="mailto:prearrivalinterview@goldendoor.com">prearrivalinterview@goldendoor.com</Link>
                    </Typography>
                    <Box mt={2} textAlign="center">
                      <Link href="https://outlook.office365.com/owa/calendar/GoldenDoor@goldendoor.com/bookings/"
                        target="_blank"
                        rel="noopener"
                        style={{ fontWeight: 'bold', textDecoration: 'none', fontSize: 22 }}
                      >
                        Schedule Pre-arrival Call
                      </Link>
                    </Box>
                  </Box>}
                </Container>

              </>
            )}
          </Box>
        )}
        {loading ? <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 0.8)"
        >
          <CircularProgress size={60} />
        </Box> : ''}
      </Box>
    </ThemeProvider>
  );
}

export default App;
