var api : any = {};
api.getValidation = () => {
    return new Promise((resolve, reject) => {
        const uRLQueryString = window.location.search;
        console.log(uRLQueryString);
        const urlParams = new URLSearchParams(uRLQueryString);
        const reservationNumber = urlParams.get('ReservationID');
        console.log(reservationNumber);
        let fetchURL = `${process.env.REACT_APP_GET_RESERVATION_API_URL}&reservationNumber=${reservationNumber}`;
        let fetchURLFailure = process.env.REACT_APP_AZURE_LOGIC_APP_URL as string;
        const errorData = {
            ErrorDate: new Date().toISOString(),
            ErrorFunctionName: 'GetValidation',
            ReservationNo: reservationNumber,
            ErrorMessage: 'Getting data for validating user has been failed please check function run logs.'
        };
        fetch(fetchURL, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
            }
        }).then((res) => {

            if(res.status !== 200){
                if(res.status !== 500)
                {
                    res.text().then(err => {
                          fetch(fetchURLFailure, {
                              method: 'POST',
                          })
                            reject(err);
                        }).catch((err1) => {
                            fetch(fetchURLFailure, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(errorData)
                            })
                            reject(err1);
                    });
                }
                else{
                    res.text().then(err => {
                        fetch(fetchURLFailure, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(errorData)
                        })
                        reject(err);
                    })
                }
            }else{
                res.json().then(data => {
                   resolve(data)       
               });
            }
        }).catch((err) => {
            fetch(fetchURLFailure, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(errorData)
            })
                reject(err);
            }).catch((err1) => {
                fetch(fetchURLFailure, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(errorData)
                })
                reject(err1);
        });
    });
}
// api.postFormData = (formData : any) => {
//     return new Promise((resolve, reject) => {
//         let fetchURL = `https://goldendoorservices.azurewebsites.net/api/UpdateReservation?code=b1xEe0V7Oha7WLKfuaRIPNjxjTFRJzXBnakv8xd3WquoAzFuL2JxaQ%3D%3D`;
//       // let formDataObj : any = formData.formData;
//         fetch(fetchURL, {
//             method: 'POST',
//             body: JSON.stringify(formData),
//         }).then((res) => {

//             if(res.status !== 200){
//                reject("Failed")
//             }else{
//                 resolve("Success");
//             }
//         }).catch((err) => {
//                 reject(err);
//             }).catch((err1) => {
//                 reject(err1);
//         });
//     });
// }
api.postFormDataForFlow = (formData : any) => {
    return new Promise((resolve, reject) => {
        let fetchURL = process.env.REACT_APP_AZURE_LOGIC_APP_URL as string;
      // let formDataObj : any = formData.formData;
        fetch(fetchURL, {
            method: 'POST',
            body: JSON.stringify(formData),
        }).then((res) => {

            if((res.status !== 202)){
               reject("Failed")
            }else{
                resolve("Success");
            }
        }).catch((err) => {
                reject(err);
            }).catch((err1) => {
                reject(err1);
        });
    });
}
export default api